import { format } from 'date-fns';

export function formattedDate(rowDate, dateFormat = 'dd.MM.yyyy') {
    return rowDate ? format(new Date(rowDate), dateFormat) : null;
}

export function urlRusLat(str) {
    const row = str.toLowerCase(); // все в нижний регистр
    const cyr2latChars = [['а', 'a'], ['б', 'b'], ['в', 'v'], ['г', 'g'],
        ['д', 'd'], ['е', 'e'], ['ё', 'yo'], ['ж', 'zh'], ['з', 'z'],
        ['и', 'i'], ['й', 'y'], ['к', 'k'], ['л', 'l'],
        ['м', 'm'], ['н', 'n'], ['о', 'o'], ['п', 'p'], ['р', 'r'],
        ['с', 's'], ['т', 't'], ['у', 'u'], ['ф', 'f'],
        ['х', 'h'], ['ц', 'tc'], ['ч', 'ch'], ['ш', 'sh'], ['щ', 'sch'],
        ['ъ', ''], ['ы', 'y'], ['ь', ''], ['э', 'e'], ['ю', 'yu'], ['я', 'ya'],

        ['А', 'a'], ['Б', 'b'], ['В', 'v'], ['Г', 'g'],
        ['Д', 'd'], ['Е', 'e'], ['Ё', 'yo'], ['Ж', 'zh'], ['З', 'z'],
        ['И', 'i'], ['Й', 'y'], ['К', 'k'], ['Л', 'l'],
        ['М', 'm'], ['Н', 'n'], ['О', 'o'], ['П', 'p'], ['Р', 'r'],
        ['С', 's'], ['Т', 't'], ['У', 'u'], ['Ф', 'f'],
        ['Х', 'h'], ['Ц', 'tc'], ['Ч', 'ch'], ['Ш', 'sh'], ['Щ', 'sch'],
        ['Ъ', ''], ['Ы', 'y'],
        ['Ь', ''],
        ['Э', 'e'],
        ['Ю', 'yu'],
        ['Я', 'ya'],

        ['a', 'a'], ['b', 'b'], ['c', 'c'], ['d', 'd'], ['e', 'e'],
        ['f', 'f'], ['g', 'g'], ['h', 'h'], ['i', 'i'], ['j', 'j'],
        ['k', 'k'], ['l', 'l'], ['m', 'm'], ['n', 'n'], ['o', 'o'],
        ['p', 'p'], ['q', 'q'], ['r', 'r'], ['s', 's'], ['t', 't'],
        ['u', 'u'], ['v', 'v'], ['w', 'w'], ['x', 'x'], ['y', 'y'],
        ['z', 'z'],

        ['A', 'A'], ['B', 'B'], ['C', 'C'], ['D', 'D'], ['E', 'E'],
        ['F', 'F'], ['G', 'G'], ['H', 'H'], ['I', 'I'], ['J', 'J'], ['K', 'K'],
        ['L', 'L'], ['M', 'M'], ['N', 'N'], ['O', 'O'], ['P', 'P'],
        ['Q', 'Q'], ['R', 'R'], ['S', 'S'], ['T', 'T'], ['U', 'U'], ['V', 'V'],
        ['W', 'W'], ['X', 'X'], ['Y', 'Y'], ['Z', 'Z'],

        [' ', '_'], ['0', '0'], ['1', '1'], ['2', '2'], ['3', '3'],
        ['4', '4'], ['5', '5'], ['6', '6'], ['7', '7'], ['8', '8'], ['9', '9'],
        ['-', '-']];

    let newStr = String();

    for (let i = 0; i < row.length; i += 1) {
        const ch = row.charAt(i);
        let newCh = '';

        for (let j = 0; j < cyr2latChars.length; j += 1) {
            if (ch === cyr2latChars[j][0]) {
                // eslint-disable-next-line prefer-destructuring
                newCh = cyr2latChars[j][1];
            }
        }
        newStr += newCh;
    }

    return newStr.replace(/[_]{2,}/gim, '_').replace(/\n/gim, '');
}

export function subset(obj, keys) {
    // eslint-disable-next-line no-return-assign,no-param-reassign,no-sequences
    return keys.reduce((a, b) => (a[b] = obj[b], a), {});
}

export function toPretty(amount, fixed = 2) {
    return amount ? (parseInt(amount, 10) / 100).toFixed(fixed) : 0;
}

export function toInteger(amount) {
    return amount ? Math.round(amount * 100) : 0;
}

export default {
    formattedDate, urlRusLat, subset,
};
