export default [
  {
    path: '/product/:id/specification/create',
    name: 'specification-create',
    component: () => import('@/views/products/SpecificationCreate.vue'),
    meta: {
      pageTitle: 'Создание спецификации товара',
      // action: 'read',
      resource: 'products',
    },
  },
  {
    path: '/specification/:id/update',
    name: 'specification-update',
    component: () => import('@/views/products/SpecificationUpdate.vue'),
    meta: {
      pageTitle: 'Редактирование спецификации товара',
      // action: 'read',
      resource: 'products',
    },
  },
];
