import { get, post } from '@/api/general';

export default {
  list: (params) => get('crm/external-options', params),
  get: (optionId) => get(`crm/external-options/${optionId}`),
  create: (data) => post('crm/external-options/create', data),
  update: (optionId, data) => post(`crm/external-options/${optionId}/update`, data),
  addCategory: (optionId, data) => post(`crm/external-options/${optionId}/add-category`, data),
  delete: (optionId) => post(`crm/external-options/${optionId}/delete`),
};
