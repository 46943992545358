import { get, post } from '@/api/general';

export default {
    list: (params) => get('sizes', params),
    create: (body) => post('crm/sizes/create', body),
    get: (id) => get(`crm/sizes/${id}`),
    addCategory: (id, body) => post(`crm/sizes/${id}/add-category`, body),
    update: (id, body) => post(`crm/sizes/${id}/update`, body),
    delete: (id) => post(`crm/sizes/${id}/delete`),
};
