export default [
  {
    path: '/turnover',
    name: 'turnover',
    component: () => import('@/views/analytics/Turnover.vue'),
    meta: {
      pageTitle: 'Оборотная ведомость',
      contentClass: 'ecommerce-application',
      resource: 'turnover',
    },
  },
];
