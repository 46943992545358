import { get, post, download } from '@/api/general';

export default {
    list: (params) => get('crm/products', params),
    search: (params) => get('products/search', params),
    searchAll: (params) => get('products/search/all', params),
    getStockInfo: (productId) => get(`products/${productId}/stockInfo`),
    downloadImages: (productId, fileName = null) => download(`products/${productId}/images/zip`, fileName),
    filterOptions: (params) => get('products/filters', params),
    get: (productId) => get(`crm/products/${productId}`),
    update: (productId, body) => post(`crm/products/${productId}/update`, body),
    create: (body) => post('crm/products/create', body),
    uploadImages: (productId, body) => post(`crm/products/${productId}/images/upload`, body),
    removeImage: (productId, body) => post(`crm/products/${productId}/images/remove`, body),
    setMainImage: (productId, body) => post(`crm/products/${productId}/images/setMain`, body),
    vendorSearch: (params) => get('crm/products/vendorSearch', params),
    sizes: (productId, params) => get(`products/${productId}/sizes`, params),
    dictionaries: (productId) => get(`crm/products/${productId}/dictionaries`),
    specificationOptions: (productId) => get(`crm/products/${productId}/specification/options`),
};
