import { get, post } from '@/api/general';

export default {
    list: (params) => get('crm/categories', params),
    get: (categoryId) => get(`crm/categories/${categoryId}`),
    options: (categoryId) => get(`crm/categories/${categoryId}/options`),
    externalOptions: (categoryId, externalServiceId) => get(`crm/categories/${categoryId}/external/${externalServiceId}/options`),
    specDictionaries: (categoryId) => get(`crm/categories/${categoryId}/specification/dictionaries`),
    specOptions: (categoryId) => get(`crm/categories/${categoryId}/specification/options`),
    addOption: (categoryId, body) => post(`crm/categories/${categoryId}/options/add`, body),
    addExternalOption: (categoryId, externalServiceId, body) => post(`crm/categories/${categoryId}/external/${externalServiceId}/options/add`, body),
    addSpecOption: (categoryId, body) => post(`crm/categories/${categoryId}/specification/options/add`, body),
    productOptions: (categoryId) => get(`categories/${categoryId}/product/options`),
    all: (params) => get('crm/categories/all', params),
    select: (params) => get('crm/categories/select', params),
    path: (categoryId) => get(`crm/categories/${categoryId}/path`),
    create: (body) => post('crm/categories/create', body),
    update: (categoryId, body) => post(`crm/categories/${categoryId}/update`, body),
};
