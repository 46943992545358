import { get, post } from '@/api/general';

export default {
    get: (productSpecificationId) => get(`crm/productSpecification/${productSpecificationId}`),
    remove: (productSpecificationId) => post(`crm/productSpecification/${productSpecificationId}/delete`),
    update: (productSpecificationId, body) => post(`crm/productSpecification/${productSpecificationId}/update`, body),
    create: (productId, body) => post(`crm/products/${productId}/specification/create`, body),
    history: (productSpecificationId) => get(`crm/productSpecification/${productSpecificationId}/history`),
    remains: (params) => get('crm/productSpecification/remains', params),
    price: {
        update: (productSpecificationId, body) => post(`crm/productSpecification/${productSpecificationId}/price/update`, body),
    },
    label: (productSpecificationId) => get(`crm/productSpecification/${productSpecificationId}/print/label`),
};
