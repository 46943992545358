import { get, post } from '@/api/general';

export default {
    list: (params) => get('brands', params),
    select: (params) => get('crm/brands/select', params),
    create: (body) => post('crm/brands/create', body),
    get: (id) => get(`crm/brands/${id}`),
    addCategory: (id, body) => post(`crm/brands/${id}/add-category`, body),
    update: (id, body) => post(`crm/brands/${id}/update`, body),
};
