import { get, post } from '@/api/general';

export default {
    list: (params) => get('crm/orders', params),
    dashboard: () => get('crm/dashboard/orders'),
    get: (orderId) => get(`orders/${orderId}`),
    statuses: (params) => get('order/statuses', params),
    create: (params) => post('crm/orders/create', params),
    update: (orderId, params) => post(`crm/orders/${orderId}/update`, params),
    delete: (orderId) => post(`crm/orders/${orderId}/delete`),
    statusUpdate: (orderId, params) => post(`crm/orders/${orderId}/status/update`, params),
};
