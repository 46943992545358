export default [
  {
    path: '/chat',
    name: 'chat',
    component: () => import('@/views/apps/chat/Chat.vue'),
    meta: {
      contentRenderer: 'sidebar-left',
      contentClass: 'chat-application',
      resource: 'chat',
    },
  },
  {
    path: '/brands',
    name: 'brands',
    component: () => import('@/views/brands/BrandList.vue'),
    meta: {
      pageTitle: 'Бренды',
      resource: 'brands',
      breadcrumb: [
        {
          text: 'Все бренды',
          active: true,
        },
      ],
    },
  },
  {
    path: '/sizes',
    name: 'sizes',
    component: () => import('@/views/sizes/SizeList.vue'),
    meta: {
      pageTitle: 'Размеры',
      resource: 'sizes',
      breadcrumb: [
        {
          text: 'Все размеры',
          active: true,
        },
      ],
    },
  },
  {
    path: '/sizes/:id',
    name: 'size-details',
    component: () => import('@/views/sizes/SizeDetails.vue'),
    meta: {
      pageTitle: 'Размеры',
      resource: 'sizes',
    },
  },
  {
    path: '/brands/:id?',
    name: 'brand-details',
    component: () => import('@/views/brands/BrandDetails.vue'),
    meta: {
      pageTitle: 'Бренды',
      resource: 'brands',
      breadcrumb: [
        {
          text: 'Все бренды',
          active: true,
        },
      ],
    },
  },
    // Categories
  {
    path: '/categories/:id?',
    name: 'category-list',
    component: () => import('@/views/categories/CategoryList.vue'),
    meta: {
      pageTitle: 'Категории',
      resource: 'categories',
      breadcrumb: [
        {
          text: 'Все категории',
          active: true,
        },
      ],
    },
  },
  {
    path: '/categories/:id/details/:tab?',
    name: 'category-details',
    props: true,
    component: () => import('@/views/categories/CategoryDetails.vue'),
    meta: {
      pageTitle: 'Категории',
      resource: 'categories',
    },
  },
  // Orders
  {
    path: '/orders',
    name: 'order-list',
    component: () => import('@/views/orders/Orders.vue'),
    meta: { resource: 'orders' },
  },
  {
    path: '/order/create',
    name: 'order-create',
    component: () => import('@/views/orders/OrderCreate.vue'),
    meta: { action: 'manage', resource: 'orders' },
  },
  {
    path: '/order/:id',
    name: 'order-details',
    component: () => import('@/views/orders/OrderDetails.vue'),
    meta: { resource: 'orders' },
  },
  {
    path: '/order/:id/edit',
    name: 'order-edit',
    component: () => import('@/views/orders/OrderEdit.vue'),
    meta: { action: 'manage', resource: 'orders' },
  },
    // Arrivals
  {
    path: '/arrivals',
    name: 'arrivals',
    component: () => import('@/views/arrivals/Arrivals.vue'),
    meta: { resource: 'arrivals' },
  },
  {
    path: '/arrival/create',
    name: 'arrival-create',
    component: () => import('@/views/arrivals/ArrivalCreate.vue'),
    meta: { action: 'create', resource: 'arrivals' },
  },
  {
    path: '/arrival/:id',
    name: 'arrival-details',
    component: () => import('@/views/arrivals/ArrivalDetails.vue'),
    meta: { resource: 'arrivals' },
  },
  {
    path: '/arrival/:id/edit',
    name: 'arrival-edit',
    component: () => import('@/views/arrivals/ArrivalEdit.vue'),
    meta: { action: 'update', resource: 'arrivals' },
  },
   // Returns
  {
    path: '/returns',
    name: 'returns',
    component: () => import('@/views/returns/Returns.vue'),
    meta: { resource: 'returns' },
  },
  {
    path: '/return/create',
    name: 'return-create',
    component: () => import('@/views/returns/ReturnCreate.vue'),
    meta: { action: 'create', resource: 'returns' },
  },
  {
    path: '/return/:id',
    name: 'return-details',
    component: () => import('@/views/returns/ReturnDetails.vue'),
    meta: { resource: 'returns' },
  },
  {
    path: '/return/:id/edit',
    name: 'return-edit',
    component: () => import('@/views/returns/ReturnEdit.vue'),
    meta: { action: 'manage', resource: 'returns' },
  },
    // Payments
  {
    path: '/payment/list',
    name: 'payment-list',
    component: () => import('@/views/payments/Payments.vue'),
  },
    // History
  {
    path: '/history',
    name: 'history',
    component: () => import('@/views/history/History.vue'),
    meta: { resource: 'history' },
  },
  // Import
  {
    path: '/import',
    name: 'import',
    component: () => import('@/views/import/Import.vue'),
    meta: { resource: 'import' },
  },
  {
    path: '/bank-accounts',
    name: 'bank-accounts',
    component: () => import('@/views/bank-accounts/BankAccounts.vue'),
    meta: { resource: 'bank-accounts' },
  },
  {
    path: '/expenses/list',
    name: 'expense-list',
    component: () => import('@/views/expenses/Expenses.vue'),
  },
  {
    path: '/users',
    name: 'user-list',
    component: () => import('@/views/users/Users.vue'),
    meta: { resource: 'users', pageTitle: 'Пользователи' },
  },
  {
    path: '/settlements',
    name: 'settlements',
    component: () => import('@/views/reports/Settlements.vue'),
    meta: { resource: 'users', pageTitle: 'Взаиморасчеты' },
  },
  {
    path: '/remains',
    name: 'remains',
    component: () => import('@/views/reports/Remains.vue'),
    meta: {
      pageTitle: 'Товарные остатки',
      resource: 'remains',
    },
  },
  {
    path: '/price-list',
    name: 'price-list',
    component: () => import('@/views/reports/PriceList.vue'),
    meta: {
      pageTitle: 'Прайс-лист',
      resource: 'prices',
    },
  },
  {
    path: '/email/templates',
    name: 'email-templates',
    component: () => import('@/views/email/Templates.vue'),
    meta: {
      pageTitle: 'Емейл рассылка',
      resource: 'emails',
    },
  },
  {
    path: '/email/mailing-list',
    name: 'mailing-list',
    component: () => import('@/views/email/MailingList.vue'),
    meta: {
      pageTitle: 'Емейл рассылка',
      resource: 'emails',
    },
  },
  {
    path: '/inventory',
    name: 'inventory',
    component: () => import('@/views/reports/Inventory.vue'),
    meta: {
      pageTitle: 'Инвентаризация',
      resource: 'inventory',
    },
  },
  {
    path: '/user/:id',
    name: 'user-details',
    component: () => import('@/views/users/UserDetails.vue'),
    meta: {
      pageTitle: 'Детали пользователя',
      resource: 'users',
    },
  },
];
