import { get, post } from '@/api/general';

export default {
    list: (params) => get('crm/arrivals', params),
    // dashboard: () => get('dashboard/orders'),
    get: (arrivalId) => get(`crm/arrivals/${arrivalId}`),
    delete: (arrivalId) => post(`crm/arrivals/${arrivalId}/delete`),
    statuses: (params) => get('crm/arrivals/statuses', params),
    create: (params) => post('crm/arrivals/create', params),
    update: (arrivalId, params) => post(`crm/arrivals/${arrivalId}/update`, params),
};
