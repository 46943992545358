import { get, post } from '@/api/general';

export default {
    list: (params) => get('crm/users', params),
    get: (userId) => get(`crm/users/${userId}`),
    turnover: (userId) => get(`crm/users/${userId}/turnover`),
    paginated: (params) => get('crm/users/paginated', params),
    suppliers: (params) => get('crm/suppliers', params),
    delete: (userId) => post(`crm/users/${userId}/delete`),
};
