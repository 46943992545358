import Vue from 'vue';
import orders from '@/api/orders';
import returns from '@/api/returns';
import payments from '@/api/payments';
import products from '@/api/products';
import auth from '@/api/auth';
import chat from '@/api/chat';
import users from '@/api/users';
import categories from '@/api/categories';
import conditions from '@/api/conditions';
import productSpecifications from '@/api/productSpecifications';
import brands from '@/api/brands';
import colors from '@/api/colors';
import countries from '@/api/countries';
import arrivals from '@/api/arrivals';
import currencies from '@/api/currencies';
import bankAccounts from '@/api/bankAccounts';
import services from '@/api/services';
import expenses from '@/api/expenses';
import templates from '@/api/templates';
import costs from '@/api/costs';
import reports from '@/api/reports';
import categoryDictionary from '@/api/categoryDictionary';
import categoryExternalDictionary from '@/api/categoryExternalDictionary';
import userDictionary from '@/api/userDictionary';
import sizes from '@/api/sizes';
import options from '@/api/options';
import specOptions from '@/api/specOptions';
import batches from '@/api/batches';
import priceList from '@/api/priceList';
import externalOptions from '@/api/externalOptions';

Vue.prototype.$api = {
  orders,
  returns,
  auth,
  payments,
  products,
  chat,
  users,
  categories,
  conditions,
  productSpecifications,
  brands,
  colors,
  countries,
  arrivals,
  currencies,
  bankAccounts,
  services,
  expenses,
  costs,
  reports,
  templates,
  categoryDictionary,
  userDictionary,
  sizes,
  options,
  batches,
  priceList,
  specOptions,
  externalOptions,
  categoryExternalDictionary,
};
