export default [
  {
    path: '/external-options',
    name: 'external-options',
    component: () => import('@/views/externalOptions/ExternalOptionList.vue'),
    meta: {
      pageTitle: 'Внешние Справочники',
      resource: 'external-options',
    },
  },
  {
    path: '/external-options/:id?',
    name: 'external-option-update',
    component: () => import('@/views/externalOptions/ExternalOptionUpdate.vue'),
    meta: {
      pageTitle: 'Детали внешнего справочника',
      resource: 'external-options',
    },
  },
];
